<template>
    <div style="width:100%;height:100%">
        <div class="reportDetail">
            <div class="detailRight">
                <div class="reportBtns">
                    <div class="btnClass">
                        <span class="dotClass"></span>
                        <span
                            style="color:#8E9099"
                        >{{selfData.type == 1?'智能数据报告':selfData.type == 2?'地区对比报告':'时间对比报告'}} >&nbsp;</span>
                        <span class="navigiOn">报告详情</span>
                    </div>
                    <div class="backBtn" @click="back(selfData.type)">
                        <i class="iconfont">&#xe606;</i>
                        <span>返回</span>
                    </div>
                </div>
                <div class="detailBox" id="pdfDom">
                    <p class="bigTit">{{selfData.title}}</p>
                    <div class="markInfo">
                        <div class="source">
                            来源：
                            <span>行列科技</span>
                        </div>
                        <div class="timeInfo">
                            发布时间：
                            <span>{{selfData.create_time}}</span>
                        </div>
                    </div>
                    <div class="summary">{{selfData.description}}</div>

                    <div class="mianTable">
                        <div>
                            <div
                                class="m-table"
                                v-for="(item,index1) in listdata"
                                :key="index1+item.id"
                                v-show="selfData.type == 1"
                                style="margin-top:20px"
                            >
                                <p class="mateName">{{item.name}}</p>
                                <ul class="mateList">
                                    <li class="listName">
                                        <span class="colItem col1">材料名称</span>
                                        <span class="colItem col2">环比指数</span>
                                        <span class="colItem col3">环比增长速度（%）</span>
                                    </li>
                                    <li
                                        class="listItem"
                                        v-for="(item1,index2) in item.children"
                                        :key="index2"
                                    >
                                        <span class="colItem1 col1">{{item1.name}}</span>
                                        <span class="colItem1 col2">
                                            <template
                                                v-if="item1.chain_index&&item1.chain_index != {}"
                                            >{{item1.chain_index|numText}}</template>
                                            <template v-else>--</template>
                                        </span>
                                        <span class="colItem1 col3">
                                            <template
                                                v-if="item1.chain_rate&&item1.chain_rate != {}"
                                            >{{item1.chain_rate|numText}}</template>
                                            <template v-else>--</template>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="notes" v-html="note" v-show="selfData.type == 1"></div>

                            <div
                                v-for="(item,index) of listdata"
                                :key="index"
                                v-show="selfData.type == 2 || selfData.type == 3"
                            >
                                <p
                                    class="itemTitle"
                                    v-show="selfData.type == 2"
                                >{{item.name}}材料多地区指数对比分析</p>
                                <p
                                    class="itemTitle"
                                    v-show="selfData.type == 3"
                                >{{otherData.areas_name}}{{item.name}}材料各时间段指数对比分析</p>
                                <el-popover
                                    class="sm"
                                    placement="bottom-start"
                                    width="200"
                                    trigger="hover"
                                    title="指数说明"
                                >
                                    <p>反映了市场材料价格变动情况的相对数。定基指数=（报告期价格/基期价格）* 基期价格指数</p>
                                    <p>基准期：2020年3月</p>
                                    <p>基期价格指数：1000</p>
                                    <div slot="reference">指数定义说明</div>
                                </el-popover>
                                <ul class="mateList" v-show="selfData.type == 2">
                                    <li class="listName">
                                        <span class="colItem col4">地区</span>
                                        <span class="colItem col7">{{item.pre_name}}(环比)</span>
                                        <span class="colItem col5">{{item.year_name}}(同比)</span>
                                        <span class="colItem col6">{{item.now}}</span>
                                        <span class="colItem col9">环比增长速度（%）</span>
                                        <span class="colItem col8">同比增长速度（%）</span>
                                    </li>
                                    <li
                                        class="listItem"
                                        v-for="(item1,index) of item.data"
                                        :key="index"
                                    >
                                        <span class="colItem1 col4">{{item1.name}}</span>
                                        <span class="colItem1 col7">
                                            <template
                                                v-if="item1.prevData&&item1.prevData.base_index"
                                            >{{item1.prevData.base_index|numText}}</template>
                                            <template v-else>--</template>
                                        </span>
                                        <span class="colItem1 col5">
                                            <template
                                                v-if="item1.prevYearsData&&item1.prevYearsData.base_index"
                                            >{{item1.prevYearsData.base_index|numText}}</template>
                                            <template v-else>--</template>
                                        </span>
                                        <span class="colItem1 col6">
                                            <template
                                                v-if="item1.data&&item1.data.base_index"
                                            >{{item1.data.base_index|numText}}</template>
                                            <template v-else>--</template>
                                        </span>
                                        <span class="colItem1 col9">
                                            <template
                                                v-if="item1.data&&item1.data.chain_rate"
                                            >{{item1.data.chain_rate|numText}}</template>
                                            <template v-else>--</template>

                                            <img
                                                src="../../../assets/img/上.png"
                                                alt
                                                v-show="item1.data && item1.data.chain_rate && Number(item1.data.chain_rate) > 0"
                                            />
                                            <img
                                                src="../../../assets/img/下.png"
                                                alt
                                                v-show="item1.data && item1.data.chain_rate && Number(item1.data.chain_rate) < 0"
                                            />
                                            <img
                                                src="../../../assets/img/bp.png"
                                                alt
                                                v-show="item1.data && item1.data.chain_rate && Number(item1.data.chain_rate) == 0"
                                            />
                                        </span>
                                        <span class="colItem1 col8">
                                            <template
                                                v-if="item1.data&&item1.data.years_rate"
                                            >{{item1.data.years_rate|numText}}</template>
                                            <template v-else>--</template>
                                            <img
                                                src="../../../assets/img/上.png"
                                                alt
                                                v-show="item1.data && item1.data.years_rate && Number(item1.data.years_rate) > 0"
                                            />
                                            <img
                                                src="../../../assets/img/下.png"
                                                alt
                                                v-show="item1.data && item1.data.years_rate && Number(item1.data.years_rate) < 0"
                                            />
                                            <img
                                                src="../../../assets/img/bp.png"
                                                alt
                                                v-show="item1.data && item1.data.years_rate && Number(item1.data.years_rate) == 0"
                                            />
                                        </span>
                                    </li>
                                </ul>
                                <ul class="mateList" v-show="selfData.type == 3">
                                    <li class="listName">
                                        <span
                                            class="colItem"
                                            v-for="item1 in item.data"
                                            :key="item1.id"
                                            :style="'width:' + itemWidth + '%'"
                                        >{{item1.name?item1.name:'--'}}</span>
                                    </li>
                                    <li class="listItem">
                                        <span
                                            class="colItem1"
                                            v-for="item1 in item.data"
                                            :key="item1.id"
                                            :style="'width:' + itemWidth + '%'"
                                        >
                                            <template
                                                v-if="item1.data.base_index&&item1.data.base_index != {}"
                                            >{{item1.data.base_index|numText}}</template>
                                            <template v-else>--</template>
                                        </span>
                                    </li>
                                </ul>
                                <p
                                    class="itemTitle"
                                    v-show="selfData.type == 2"
                                >{{item.name}}材料多地区指数同比统计图</p>
                                <div
                                    :id="'mainA' + item.id"
                                    ref="chart1"
                                    style="width: 900px;height:400px;margin:0 auto"
                                    v-show="selfData.type == 2"
                                ></div>
                                <p
                                    class="itemTitle"
                                    v-show="selfData.type == 2"
                                >{{item.name}}材料多地区指数环比统计图</p>
                                <div
                                    :id="'mainB' + item.id"
                                    style="width: 900px;height:400px;margin:0 auto"
                                    v-show="selfData.type == 2"
                                ></div>
                                <p
                                    class="itemTitle"
                                    v-show="selfData.type == 2"
                                >{{item.name}}材料多地区指数同比、环比增长趋势统计图</p>
                                <div
                                    :id="'mainC' + item.id"
                                    style="width: 900px;height:400px;margin:0 auto"
                                    v-show="selfData.type == 2"
                                ></div>
                                <p
                                    class="itemTitle"
                                    v-show="selfData.type == 3"
                                >{{otherData.areas_name}}{{item.name}}材料各时间段指数对比统计图</p>
                                <div
                                    :id="'mainD' + item.id"
                                    style="width: 900px;height:400px;margin:0 auto"
                                    v-show="selfData.type == 3"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-button type="danger" v-print="printObj" class="downIcon">
            <i class="iconfont">&#xe60c;</i>
        </el-button>
        <el-tooltip placement="top" content="回到顶部">
            <BackToTop
                :custom-style="myBackToTopStyle"
                :visibility-height="300"
                :back-position="0"
                transition-name="fade"
            />
        </el-tooltip>
    </div>
</template>
<script>
// import { datawork } from "../../plugins/datawork.js";
// import { getToken } from "../../plugins/gettoken.js";
import BackToTop from "./back-to-top";

export default {
    filters: {
        numText(num) {
            let result = "";
            if (parseFloat(num).toString() == "NaN") {
                result = "--";
            } else {
                result = Number(num).toFixed(2);
            }

            return result;
        },
    },
    data() {
        return {
            selfData: {},
            otherData: {},
            reId: 0,
            listdata: [],
            userid: 0,
            note: "",
            tbdate: "",
            hbdate: "",
            printObj: {},
            now: "",
            itemWidth: 10,
            myBackToTopStyle: {
                right: "50px",
                bottom: "50px",
                width: "40px",
                height: "40px",
                borderRadius: "4px",
                lineHeight: "45px", // 请保持与高度一致以垂直居中
                // background: '#78A4FE'// 按钮的背景颜色
            },
        };
    },
    components: { BackToTop },
    created() {
        this.reId = Number(this.$route.query.id);

        this.get_detail();
        this.printObj = {
            id: "pdfDom",
            popTitle: this.selfData ? this.selfData.title : "",
            extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        };
    },
    mounted() {},
    methods: {
        handleTitle(index) {
            // if (this.showIndex == index) return;
            // this.showIndex = index;
            this.$emit("changeSideItem", index); // 通知父组件改变索引状态
            // this.$emit("changeSideTitleIndex", index);
        },
        async get_detail() {
            let id = this.reId;
            let res = await this.$reqApi.getDetail({ id: id });
            this.selfData = res.selfData;
            this.listdata = res.data;
            this.otherData = res.otherData;
            this.$nextTick(() => {
                if (this.selfData.type == 2) {
                    // this.nowDate(this.selfData.type);
                    this.listdata.forEach((item) => {
                        this.renderGraph(item, item.id);
                        this.renderGraph2(item, item.id);
                        this.renderGraph3(item, item.id);
                    });
                } else if (this.selfData.type == 3) {
                    this.listdata.forEach((item) => {
                        this.itemWidth = 100 / item.data.length;
                        this.renderGraph4(item, item.id);
                    });
                }
            });

            this.handleTitle(this.selfData.type);
        },
        nowDate(data) {
            if (data == 1) {
                this.now = this.terms[0].slice(0, 4) + "年";
            } else if (data == 3) {
                this.now =
                    this.terms[0].slice(0, 4) +
                    "年" +
                    this.terms[0].slice(6, 7) +
                    "月";
            } else {
                if (this.terms[0].slice(6, 7) == 1) {
                    this.now = this.terms[0].slice(0, 4) + "年第一季度";
                } else if (this.terms[0].slice(6, 7) == 4) {
                    this.now = this.terms[0].slice(0, 4) + "年第二季度";
                } else if (this.terms[0].slice(6, 7) == 7) {
                    this.now = this.terms[0].slice(0, 4) + "年第三季度";
                } else {
                    this.now = this.terms[0].slice(0, 4) + "年第四季度";
                }
            }
        },
        renderGraph(data, aa) {
            let x = [];
            let y = [];
            let z = [];
            let label1 = data.now + "指数";
            let label2 = data.year_name + "指数";
            const mycharts = this.$echarts.init(
                document.getElementById("mainA" + aa)
            );
            if (data && data.data) {
                data.data.forEach((item) => {
                    x.push(item.name);
                    if (item.data) {
                        if (item.data.base_index) {
                            z.push(Number(item.data.base_index));
                        } else {
                            z.push(0);
                        }
                    } else {
                        z.push(0);
                    }
                    if (item.prevYearsData) {
                        if (item.prevYearsData.base_index) {
                            y.push(Number(item.prevYearsData.base_index));
                        } else {
                            y.push(0);
                        }
                    } else {
                        y.push(0);
                    }
                });
            }
            var option = {
                xAxis: {
                    data: x,
                    axisLabel: {
                        textStyle: {
                            color: "#5C5D62",
                        },
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLine: {
                        show: true,
                    },
                    z: 10,
                },
                yAxis: {
                    name: "指数",
                    nameTextStyle: {
                        color: "#8E9099",
                    },
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#5C5D62",
                        },
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                legend: {
                    data: [label1, label2],
                },
                series: [
                    {
                        name: label1,
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#FF6278" },
                                        { offset: 1, color: "#F0314C" },
                                    ]
                                ),
                            },
                        },
                        barWidth: 20,
                        data: z,
                    },
                    {
                        name: label2,
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#8F65F7" },
                                        { offset: 1, color: "#6B41ED" },
                                    ]
                                ),
                            },
                        },
                        barWidth: 20,
                        data: y,
                    },
                ],
            };
            mycharts.setOption(option, true);
        },
        renderGraph2(data, aa) {
            let x = [];
            let y = [];
            let z = [];
            let label1 = data.now + "指数";
            let label2 = data.pre_name + "指数";
            const mycharts = this.$echarts.init(
                document.getElementById("mainB" + aa)
            );
            if (data && data.data) {
                data.data.forEach((item) => {
                    x.push(item.name);
                    if (item.data) {
                        if (item.data.base_index) {
                            z.push(Number(item.data.base_index));
                        } else {
                            z.push(0);
                        }
                    } else {
                        z.push(0);
                    }
                    if (item.prevData) {
                        if (item.prevData.base_index) {
                            y.push(Number(item.prevData.base_index));
                        } else {
                            y.push(0);
                        }
                    } else {
                        y.push(0);
                    }
                });
            }
            var option = {
                xAxis: {
                    data: x,
                    axisLabel: {
                        textStyle: {
                            color: "#5C5D62",
                        },
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLine: {
                        show: true,
                    },
                    z: 10,
                },
                yAxis: {
                    name: "指数",
                    nameTextStyle: {
                        color: "#8E9099",
                    },
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#5C5D62",
                        },
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                legend: {
                    data: [label1, label2],
                },
                series: [
                    {
                        name: label1,
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#52EFA0" },
                                        { offset: 1, color: "#1CD596" },
                                    ]
                                ),
                            },
                        },
                        barWidth: 20,
                        data: z,
                    },
                    {
                        name: label2,
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#8F65F7" },
                                        { offset: 1, color: "#6B41ED" },
                                    ]
                                ),
                            },
                        },
                        barWidth: 20,
                        data: y,
                    },
                ],
            };
            mycharts.setOption(option, true);
        },
        renderGraph3(data, aa) {
            let x = [];
            let y = [];
            let z = [];
            let label1 = "同比增长速度";
            let label2 = "环比增长速度";
            const mycharts = this.$echarts.init(
                document.getElementById("mainC" + aa)
            );
            if (data && data.data) {
                data.data.forEach((item) => {
                    x.push(item.name);
                    if (item.prevYearsData) {
                        // 附加同比数据
                        if (item.prevYearsData.base_index) {
                            z.push(Number(item.prevYearsData.base_index));
                        } else {
                            z.push(0);
                        }
                    } else {
                        z.push(0);
                    }
                    if (item.prevData) {
                        // 附加环比数据
                        if (item.prevData.base_index) {
                            y.push(Number(item.prevData.base_index));
                        } else {
                            y.push(0);
                        }
                    } else {
                        y.push(0);
                    }
                });
            }
            var option = {
                xAxis: {
                    data: x,
                    axisLabel: {
                        textStyle: {
                            color: "#5C5D62",
                        },
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLine: {
                        show: true,
                    },
                    z: 10,
                },
                yAxis: {
                    name: "指数",
                    nameTextStyle: {
                        color: "#8E9099",
                    },
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#5C5D62",
                        },
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                legend: {
                    data: [label1, label2],
                },
                series: [
                    {
                        name: label1,
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#4BD7FF" },
                                        { offset: 1, color: "#3B93FF" },
                                    ]
                                ),
                            },
                        },
                        barWidth: 20,
                        data: z,
                    },
                    {
                        name: label2,
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#FFAC4B" },
                                        { offset: 1, color: "#FF704A" },
                                    ]
                                ),
                            },
                        },
                        barWidth: 20,
                        data: y,
                    },
                ],
            };
            mycharts.setOption(option, true);
        },
        renderGraph4(data, aa) {
            let x = [];
            let y = [];

            let label1 = "指数";
            // let label2 = '环比增长速度'
            const mycharts = this.$echarts.init(
                document.getElementById("mainD" + aa)
            );
            if (data && data.data) {
                data.data.forEach((item) => {
                    x.push(item.name);
                    if (item.data) {
                        if (item.data.base_index) {
                            y.push(item.data.base_index);
                        } else {
                            y.push(0);
                        }
                        // if(item.data.years_rate){
                        //     z.push(Number(item.data.years_rate))
                        // }else{
                        //     z.push(0)
                        // }
                        // if(item.data.chain_rate){
                        //     y.push(Number(item.data.chain_rate))
                        // }else{
                        //     y.push(0)
                        // }
                    } else {
                        y.push(0);
                    }
                });
            }
            var option = {
                xAxis: {
                    data: x,
                    axisLabel: {
                        textStyle: {
                            color: "#5C5D62",
                        },
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLine: {
                        show: true,
                    },
                    z: 10,
                },
                yAxis: {
                    name: "指数",
                    nameTextStyle: {
                        color: "#8E9099",
                    },
                    axisLine: {
                        show: true,
                    },
                    axisTick: {
                        show: true,
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#5C5D62",
                        },
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#999",
                        },
                    },
                },
                legend: {
                    data: [label1],
                },
                series: [
                    {
                        name: label1,
                        type: "bar",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(
                                    0,
                                    0,
                                    0,
                                    1,
                                    [
                                        { offset: 0, color: "#8293FF" },
                                        { offset: 1, color: "#5A71FF" },
                                    ]
                                ),
                            },
                        },
                        barWidth: 20,
                        data: y,
                    },
                ],
            };
            mycharts.setOption(option, true);
        },
        back(aa) {
            this.$router.push({ name: "ReportSmart", query: { type: aa } });
        },
    },
};
</script>
<style lang="less" scoped>
.btnClass,
.reportBtns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.btnClass {
    display: flex;
    min-width: 158px;
    height: 38px;
    background-color: #fff;
    padding: 0 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 8px;
    -webkit-box-shadow: 0 8px 14px 0 rgba(33, 58, 233, 0.05);
    box-shadow: 0 8px 14px 0 rgba(33, 58, 233, 0.05);
    font-size: 14px;
    color: #2c2d33;
    cursor: pointer;
    -webkit-transition: background-color 0.1s;
    transition: background-color 0.1s;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.reportDetail {
    width: 100%;
    height: auto;
    min-height: 100%;
    // overflow auto
    // display flex
}

.detailRight {
    width: 100%;
    height: auto;
    box-sizing: border-box;
}

.backBtn {
    margin-left: 20px;
    font-size: 14px;
    color: #635ef9;
    font-weight: bold;
    cursor: pointer;

    span {
        text-decoration: underline;
    }
}

.backBtn:hover {
    color: #7f94ff;
}

.detailBox {
    width: 82%;
    height: auto;
    background: #ffffff;
    padding: 60px 80px;
    // box-shadow 0px 8px 14px 0px rgba(33,58,233,0.05)
    border-radius: 8px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 60px;
}

.bigTit {
    margin-bottom: 46px;
    font-size: 24px;
    color: #2c2d33;
    line-height: 24px;
    text-align: center;
}

.markInfo {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #8e9099;
}

.timeInfo {
    margin-left: 80px;
}

.summary {
    min-height: 48px;
    height: auto;
    background: #f8f8f9;
    padding: 10px 20px;
    box-sizing: border-box;
    margin-top: 14px;
    font-size: 14px;
    color: #5c5d62;
    line-height: 28px;
    // white-space nowrap
    // text-overflow ellipsis
    // overflow hidden
}

.tableTil,
.itemTitle {
    margin: 95px 0 20px 0;
    font-size: 16px;
    color: #2c2d33;
    text-align: center;
    cursor: pointer;
}

.mateName {
    font-size: 14px;
    font-weight: bold;
    color: #2c2d33;
}

.itemTitle {
    margin-top: 60px !important;
}

.mateList {
    width: 100%;
    border-top: 1px #dcdee2 solid;
    box-sizing: border-box;
    margin: 10px 0 40px 0;

    li {
        border: 1px #dcdee2 solid;
        box-sizing: border-box;
        border-top: none;
    }
}

.listName {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #f8f8f9;
}

.listItem {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
}

.listItem:hover {
    background: #f4f5fc;
}

.colItem,
.colItem1 {
    padding: 0 20px;
    font-size: 12px;
    color: #8e9099;
    font-weight: bold;
    text-align: center;
}

.colItem1 {
    color: #5c5d62;
    font-weight: normal;
}

.col1 {
    width: 25%;
    text-align: left;
}

.col2 {
    width: 40%;
}

.col3 {
    width: 35%;
}

.col4,
.col5,
.col6,
.col7 {
    width: 15%;
}

.col8,
.col9 {
    width: 20%;
}

.col4 {
    text-align: left;
}

.notes {
    font-size: 12px;
    color: #5c5d62;
    line-height: 20px;
}

.noteTil {
    color: #8e9099;
}

.sm {
    color: #728cff;
    font-size: 12px;
    cursor: pointer;

    div {
        font-size: 12px !important;
        display: flex;
        align-items: center;
    }

    img {
        width: 12px;
        height: 12px;
        margin-left: 5px;
    }
}
</style>
<style lang="less">
.el-button.el-button--danger.downIcon {
    width: 40px;
    height: 40px;
    background: #fea178;
    padding: 0;
    border: 1px #fe7349 solid;
    box-sizing: border-box;
    position: fixed;
    right: 50px;
    bottom: 100px;

    i {
        font-size: 22px;
        color: #fff;
        text-align: center;
    }
}

.el-button.downIcon:hover {
    background: #ffb595;
    border-color: #ff825b;
}
</style>